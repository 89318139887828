import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/reset.css'
import './assets/fonts/style.css'
import './assets/main.scss'
import {createI18n} from "vue-i18n";
import enLocale from './locales/en.json';
import frLocale from './locales/ru.json';


const messages = {
    en: enLocale,
    ru: frLocale,
}
const i18n = createI18n({
    legacy:false,
    locale: 'ru',
    fallbackLocale: 'ru',
    messages
})
createApp(App).use(store).use(router).use(i18n).mount('#app')
