<template>
  <div class="list-item">
    <p class="list-item-title hover_effect" @click="toggleContent">
      {{show}}{{title}}
      <span class="accordion-toggle"><svg role="presentation" focusable="false" width="24px" height="24px" viewBox="0 0 24 24"><g stroke="none" stroke-width="1px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path d="M11,0 L11,22"></path></g></g></svg></span>
    </p>
    <div class="acc-content" v-show="isVisible" v-html="list"/>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';

const props = defineProps({
  title: String,
  list: String,
});

const isVisible = ref(false);

const toggleContent = () => {
  isVisible.value = !isVisible.value;
};
</script>

<style>
.list-item-title {
  user-select: none;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  cursor: pointer;
}
</style>