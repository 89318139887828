<template>
  <div class="contact-form">
    <div class="contact-form__title">{{ $t('contactForm.title') }}</div>
    <form class="contact-form__form" @submit.prevent="submitForm">
      <div class="contact-form__field">
        <label for="name">{{ $t('contactForm.name') }}</label>
        <input type="text" v-model="formState.name" id="name" :placeholder="$t('contactForm.name')" name="name">
      </div>
      <div class="contact-form__field">
        <label for="phone">{{ $t('contactForm.phone') }}</label>
        <!-- <input type="text" placeholder="+759 (453) 392-34-21" v-maska="maskOptions" v-model="formState.phone" id="phone" name="phone"> -->
        <input type="text" v-model="formState.phone" id="phone" :placeholder="$t('contactForm.phone')" name="phone">
      </div>
      <div class="contact-form__field">
        <label for="email">{{ $t('contactForm.email') }}</label>
        <input type="text" v-model="formState.email" id="email" :placeholder="$t('contactForm.email')" name="email">
      </div>
      <div class="contact-form__field">
        <label for="message">{{ $t('contactForm.message') }}</label>
        <textarea type="text" rows="6" v-model="formState.message" id="message" :placeholder="$t('contactForm.message')" name="message"></textarea>
      </div>
      <button class="contact-form__submit-button" :class="{ 'contact-form__submit-button--disabled': loading }"
        type="submit">
        <span 
          class="contact-form__loader-text" 
          :class="loadingStatusTextClass">
          {{ loadingStatusText }}
        </span>
        <span class="contact-form__loader" v-if="loading"></span>
        <span>{{ $t('contactForm.send') }}</span>
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { vMaska } from "maska/vue"
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type FormResponseData = {
  result: string
}

type FormSentStatus = 'sending' | 'unset' | 'sent' | 'error'

const formState = ref({
  name: '',
  phone: '',
  email: '',
  message: ''
})

const loading = ref(false)
const formSentStatus = ref<FormSentStatus>('unset')

const maskOptions = ref({
  mask: "+A (###) ###-##-##",
  eager: true,
  tokens: {
    'A': {
      pattern: /^\d+$/,
      multiple: true
    }
  }
})

const loadingStatusText = computed(() => {
  if (formSentStatus.value === 'unset') {
    return ''
  }

  if (formSentStatus.value === 'sending') {
    return ''
  }

  if (formSentStatus.value === 'sent') {
    return t('contactForm.sent')
  }

  if (formSentStatus.value === 'error') {
    return t('contactForm.error')
  }

  return ''
})

const loadingStatusTextClass = computed(() => {
  if (formSentStatus.value === 'sent') {
    return 'sent'
  }

  if (formSentStatus.value === 'error') {
    return 'error'
  }

  return ''
})

const submitForm = async () => {
  if (loading.value) {
    return
  }

  loading.value = true
  formSentStatus.value = 'sending'

  try {
    const res: FormResponseData = await (await fetch('/mail.php', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formState.value)
    })).json()
    
    
    if (parseInt(res.result) === 1) {
      formSentStatus.value = 'sent'
    } else {
      formSentStatus.value = 'error'
    }

  } catch (e) {
    console.error(e)
    formSentStatus.value = 'error'
  } finally {
    loading.value = false
  }
}

</script>

<style scoped lang="scss">
.contact-form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 80px;
  max-width: 820px;
  margin: 0 auto;
  padding: 136px 0;
  position: relative;
  z-index: 2;

  &__form {
    margin-top: 109px;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }

  &__title {
    background: linear-gradient(90deg, #A37C3D 0%, #F0DAA9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Mulish;
    font-size: 34px;
    font-weight: 300;
    line-height: 42.67px;
    text-align: left;
  }

  &__field {
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    label {
      color: rgba(163, 124, 61, 0.7);
      font-family: Mulish;
      font-size: 12px;
      font-weight: 300;
      line-height: 15.06px;
      text-align: left;
    }

    input,
    textarea {
      background: transparent;
      outline: none;
      color: #fff;
      padding: 10px 0;
      resize: none;
    }


    &::before {
      position: absolute;
      left: 0;
      bottom: -1px;
      content: ' ';
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 0.5px solid;
      border-image: linear-gradient(to right, #A37C3D 30%, #F0DAA9 100%) 47% 0%;
      width: 100%;
    }
  }

  &__submit-button {
    display: flex;
    flex-flow: row nowrap;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    color: rgba(240, 218, 169, 1);
    font-family: Mulish;
    font-size: 24px;
    font-weight: 400;
    line-height: 30.12px;
    text-align: right;
    gap: 10px;

    &--disabled {
      cursor: default;

      span:last-of-type {
        color: #818181;
        opacity: 0.4;
      }
    }
  }

  &__loader {
    width: 20px;
    height: 20px;

    border: 5px solid rgba(240, 218, 169, 1);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  &__loader-text {
    font-size: 16px;
    font-weight: 700;
    margin-right: 15px;
    &.error {
      color: #e33;
    }

    &.sent {
      color: #3e3;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}</style>