<script setup lang="ts">
defineProps({
	modelValue: {
		type: Boolean,
		default: false,
	},
	excludeHeader: {
		type: Boolean,
		default: false
	},
	includeHeader: {
		type: Boolean,
		default: false
	},
	full: {
		type: Boolean,
		default: false
	}
});
const emits = defineEmits(["update:modelValue", "onClose"]);
</script>

<template>
	<Teleport to="body">
		<Transition name="slide-l">
			<div
				class="modal-right"
				@click="emits('update:modelValue', false);"
				v-if="modelValue"
			>
				<div
					class="modal-inner"
					@click.stop
					v-show="modelValue"
				>
					<slot />
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<style lang="scss" scoped>

.slide-l-enter-active {
	transition: all 0.8s ease;
}

.slide-l-leave-active {
	transition: all 0.8s ease;
}

.slide-l-enter-active .modal-inner,
.slide-l-leave-active .modal-inner {
	transition: all 0.5s ease-in-out;
}

.slide-l-enter-from .modal-inner,
.slide-l-leave-to .modal-inner {
	transform: translateX(-100%);
}

.modal-right {
	background: hsla(198, 21%, 9%, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    z-index: 1000;
    height: 100%;

    .modal-inner {
        width: 444px;
        @media (max-width: 768px) {
            width: 100%;
        }

        height: 100%;
        position: relative;
    }
}
</style>
