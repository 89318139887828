<script setup lang="ts">
    const emits = defineEmits(['closeModal'])
</script>

<template>
    <div class="menu-modal">
        <button @click="emits('closeModal')">
            <img src="/img/icons/gamburger-dark.svg" class="gamburger" />
        </button>
        <div class="list">
            <div class="item">
                <a href="#about" @click="emits('closeModal')">
                    <p>{{ $t('menu.about') }}</p>
                </a>
            </div>
            <div class="item">
                <a href="#services" @click="emits('closeModal')">
                    <p>{{ $t('menu.services') }}</p>
                </a>
            </div>
            <div class="item">
                <a href="#portfolio" @click="emits('closeModal')">
                    <p>{{ $t('menu.portfolio') }}</p>
                </a>
            </div>
            <div class="item" @click="emits('closeModal')">
                <a href="#art">
                    <p>{{ $t('menu.art') }}</p>
                </a>
            </div>
            <div class="item" @click="emits('closeModal')">
                <a href="#footer">
                    <p>{{ $t('menu.contacts') }}</p>
                </a>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    .menu-modal {
        padding: 36px 80px;
        display: flex;
        flex-direction: column;
        background: #FCFCF8;
        width: 100%;
        height: 100%;
      @media (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
      }
        .gamburger {
            width: 32px;
            height: 23px;
        }

        .list {
            .item {
                margin-top: 48px;
                font-family: 'TT Ricordi Trial Nobili';
                font-size: 40px;
                font-weight: 600;
                line-height: 44px;
                text-align: left;

                &:hover {
                    background: linear-gradient(90deg, #A37C3D 0%, #F0DAA9 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
</style>
