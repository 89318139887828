<template>
    <div class="hello" id="portfolio">

        <swiper
            :grabCursor="false"
            :effect="'creative'"
            :direction="'horizontal'"
            :speed="800"
            :slidesPerView="1"

        :creativeEffect="{
              prev: {
                shadow: true,
                translate: [0, 0, -1],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }"
            :modules="modules"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
        >
            <button class="swiper-button-next" id="nextButton"></button>
            <button class="swiper-button-prev" id="prevButton"></button>
            <swiper-slide>
                <section ref="section" class="portfolio-item portfolio" @click="triggerDoubleSwipe(-1)" :class="{'swiper-button-next':windowWidth <768}">
                    <div class="left-part _portfolio">
                        <h3>{{$t('portfolio.main')}}</h3><img class="arrow-icon" alt="arrow" src="../assets/img/icons/arrow.svg"/>
                    </div>
                </section>
            </swiper-slide>
            <swiper-slide
                v-for="(item, index) in allItems"
                :key="item.titleKey + index"
            >

                <section ref="section" class="portfolio-item items" :class="{'swiper-button-next':windowWidth <768}">

                    <div class="left-part" v-if="(index + 1) % 2 !== 0" >
                        <img :src="item.bgImg" :alt="$t(item.titleKey)">
                    </div>
                    <div class="right-part" v-if="(index + 1) % 2 === 0">
                        <p>{{ $t(item.categoryKey) }}</p>
                        <h2 v-html="$t(item.titleKey) "></h2>
                        <img :src="item.img" :alt="$t(item.categoryKey)">
                        <p class="city">{{ $t(item.subtitleKey) }}</p>
                    </div>
                </section>
                <button class="arrow arrow-left" @click="triggerDoubleSwipe(index, 'left')"></button>
                <button class="arrow arrow-right" @click="triggerDoubleSwipe(index)"></button>
            </swiper-slide>
        </swiper>
<!--        <a href="#art" class="scroll-button _bottom">-->
<!--            <img src="/img/icons/arrow-bottom.svg" >-->
<!--        </a>-->
<!--        <a href="#services" class="scroll-button _top">-->
<!--            <img src="/img/icons/arrow-bottom.svg" >-->
<!--        </a>-->
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import 'swiper/css';
import { items } from "@/components/potfolioItems";
import 'swiper/css/effect-creative';
import { EffectCreative, Navigation } from 'swiper/modules';
import {computed, onMounted, onUnmounted, ref, nextTick} from "vue";
const modules = [EffectCreative, Navigation];
const allItems = ref(items)

const windowWidth = ref(window.innerWidth);
const buttonDisabled = ref(false);
const showSlider = ref(false);
const triggerDoubleSwipe = (index, direction = '') => {
    console.log('triggered',index);
    if (buttonDisabled.value === true || windowWidth.value <= 768) return;
    let button;
    if (direction === 'left')
        button = document.getElementById('prevButton');
    else
        button = document.getElementById('nextButton');
    buttonDisabled.value = true;
    button.dispatchEvent(new Event('click'));
    if ((index + 1) % 2 === 0 || index === -1) {
        setTimeout(() => {
            button.dispatchEvent(new Event('click'));
            setTimeout(() => {
                buttonDisabled.value = false;
            }, 1000)
        }, 1000);
    }
    else {  setTimeout(() => {
        buttonDisabled.value = false;
    }, 1000) }
}
const handleWindowResize = () => {

        windowWidth.value = window.innerWidth;

}

const allowSwiping = computed(()=>{ return windowWidth.value < 768}); // Added

onMounted(()=>{
    // showSlider.value = false

    // nextTick(() => {
    //     setTimeout(() => {
    //         if (windowWidth.value < 768) {
    //             allItems.value = [...items, ...items]
    //             showSlider.value = true
    //         }
    //     }, 100)
    // })
    window.addEventListener('resize', handleWindowResize);
})
onUnmounted(()=>{
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', handleWindowResize);
})
</script>


<style lang="scss" scoped>
.scroll-button {
    position: absolute;
    z-index: 10000;
    background: linear-gradient(107deg, rgba(27, 27, 27, 0.64) 0%, rgba(0, 0, 0, 0.64) 100%);
    backdrop-filter: blur(2px);
    padding: 8px 16px;
    display: flex;
    gap: 8px;

    &._top {
        bottom: 40px;
        left: 136px;

        img {
            transform: rotate(180deg);
        }
        @media (max-width: 768px) {
            bottom: 20px;
            left: 76px;
        }
    }

    &._bottom {
        bottom: 40px;
        left: 80px;
        @media (max-width: 768px) {
            bottom: 20px;
            left: 20px;
        }
    }


    span {
        color: #fff;
        font-family: Mulish;
        font-size: 16px;
    }
}
@media (max-width: 768px) {
    .swiper-slide:nth-child(2n) {
        display: none;
    }
}
.hello {
    position: relative;
    z-index: 1;
    overflow-x: hidden;
}

// Чтобы не лагал после 6-7 слайдов пролистанных
#portfolio .swiper-wrapper > .swiper-slide:not(.swiper-slide-visible, .swiper-slide-active, .swiper-slide-prev, .swiper-slide-next) {
    opacity: 0!important;
}

@media screen and (max-width: 768px) {
    #portfolio .swiper-wrapper > .swiper-slide:not(.swiper-slide-visible, .swiper-slide-active, .swiper-slide-prev, .swiper-slide-next) {
    opacity: 1!important;
}
}
.item {
    width: 100vw;
    //height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img.bg {
        //height: 100vh;
        object-fit: cover;
    }

    &.half {
        width: 50vw;
        background: #857A6D;
        display: flex;
        flex-direction: column;
        color: white;

        img {
            margin-bottom: 24px;
            margin-top: 24px;
        }

        h2 {
            font-family: 'TT Ricordi Trial Nobili';
            font-size: 50px;
            font-weight: 600;
            line-height: 60px;
            text-align: center;
        }

        p {
            font-size: 24px;
            font-weight: 200;
            line-height: 32px;
            text-align: center;
        }
    }
}



/* Keyframes for rainbow blinking effect */
@keyframes arrow-shaking {
    0% {
        transform:translateX(5px);
    }
    50% {
        transform:translateX(-5px);

    }
    100% {
        transform:translateX(5px);
    }
}

.portfolio-item {
    position:relative;
    cursor:pointer;
    overflow-x: hidden;
    height: 800px;
    width: 100vw;
    display: flex;
    &.portfolio{


            .arrow-icon{
                animation: arrow-shaking 1s linear infinite; /* Apply the animation infinitely */
            }

    }
    &.items{
        //cursor: e-resize;
    }
    @media (max-width: 768px) {
        height: 650px;
        //scursor: e-resize;
    }
}
.left-part {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    &._portfolio {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #857A6D;
        @media (max-width: 768px) {
            flex-direction:column;
        }


        h3 {
            color: #2F2D30;
            text-align: center;
            font-family: "TT Ricordi Trial Nobili",sans-serif;
            font-size: 104px;
            font-weight: 600;
            text-transform: uppercase;
            @media (max-width: 768px) {
                font-size: 44px;
            }
        }
        img{
            width:74px;
            height:auto;
            margin-left:66px;
            @media (max-width: 768px) {
                margin-left:0;
                margin-top:30px;
                width:40px;
            }
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.right-part {
    width: 50vw;
    padding: 0 16px;


    @media (max-width: 768px) {
        width: 100vw;
        transform: translate(0px);
    }
    height: 100%;
    background: #857A6D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
    transform: translate(100%, 0px);

    img {
        max-width: 512px;
        margin-bottom: 24px;
        margin-top: 24px;
        object-fit: contain;
        width: 100%;

    }

    h2 {
        font-family: 'TT Ricordi Trial Nobili';
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        @media (max-width: 1024px) {
            font-size: 32px;
        }
        @media (max-width: 768px) {
            font-size: 24px;
        }
    }

    p {
        text-transform: lowercase;
        @media (max-width: 768px) {
            font-size: 16px;
        }
        font-size: 24px;
        font-weight: 200;
        line-height: 32px;
        text-align: center;
    }
    .city{
        text-transform:capitalize;
    }
}
.swiper-button-next, .swiper-button-prev{
    visibility:hidden;
    &.portfolio-item{
        visibility:visible;
    }
}
.arrow{
    opacity:0;
    top:0;
    bottom:0;
    width:50%;
    position:absolute;

    &-left{
        left:0;
        cursor:w-resize;
    }
    &-right{
        left:50%;
        cursor:e-resize;
    }
    @media (max-width:768px){
        display:none;
    }

}
.swiper-slide{
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
}

.swiper-wrapper{
    -webkit-transform-style: preserve-3d;
}
</style>
